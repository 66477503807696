<template>
  <div>
    <div class='flex flex-row gap-x-2'>
      <label v-for='selectableOption in selectableOptions'
        :key='`selectableOption-${selectableOption.id}-${selectableOption.value}`'
        class='block p-2 py-3'>
        <input type='radio'
          :id='`selectableOption-${selectableOption.id}`'
          v-model='selectValue'
          :value='selectableOption.value'>
        <span :for='`selectableOption-${selectableOption.id}`' class='p-2'>{{selectableOption.title}}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditFormFieldRadioSelect',
  props: [
    'metaData',
    'value',
  ],
  data () {
    return {
      selectValue: '',
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.selectableOptions.findIndex(selectableOption => selectableOption.value === newVal)
          if (index > -1) {
            this.selectValue = newVal
          }
        } else {
          this.selectValue = ''
        }
      },
      immediate: true,
    },
    selectValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:selectValue', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    selectableOptions () {
      return this.metaData && this.metaData.selectableOptions ? this.metaData.selectableOptions : []
    },
  }
}
</script>
